import React from 'react'
import './ContactCard.css'
import {Button} from '../../Button'

const ContactCard = () => {

    return (
        <div>
            <div className='contact-card-background'>
                <img src="images/mail-142.svg" alt='mail icon'></img>
                <div className='contact-title'>
                    <h2>Thanks for getting in touch. How can I help you today?</h2>
                </div>
                <form name='contact' method='POST' data-netlify='true'>
                    <div className='contact-form'>              
                        <div className="form-group-top">
                            <label htmlFor="name">Name: </label><br />
                            <input type="text" className="form-control" name='name'></input>
                        </div>
                        <div className="form-group-top">
                            <label htmlFor="email">Email: </label><br />
                            <input id ='email' type="email" className="form-control" name='email' />
                        </div>                    
                        <div className="form-group-bottom">
                            <label htmlFor="message">Message: </label><br />
                            <textarea className="form-control-message" rows="10" name='message' />
                        </div>
                        <input type="hidden" name="form-name" value="contact" />
                    </div>
                        <Button buttonSize='btn--wide' buttonColor='primary' type='submit'>Submit</Button>
                
                </form>
            </div>
        </div>
    )
}

export default ContactCard
