import React from 'react'
import SnakeGame from './SnakeGame'
import './Snake.css'
import {Link} from 'react-router-dom'
const Snake = () => {
    return (
        <div className = 'snakeGame'>
            <Link className='back' to='/'>
            Back to Home
            </Link>
            <SnakeGame />
        </div>
    )
}

export default Snake
