import React from 'react';
import './Card.css';
import {AiFillGithub} from 'react-icons/ai'
import {MdOpenInNew} from 'react-icons/md'
import { Link } from 'react-router-dom'

const Card = ({
  projectTitle, description, showProject, githubLink, projectLink, resource, inDev, inDevText
}) => {
    return (
        <div className='cardBox'>
          <div className='cardContents'>
            <h2>{projectTitle}</h2>
            <p>{description}</p>
            <div className='inDev'>{inDev && inDevText }</div>
          </div>
            <div className="cardButtons">
            <div className='resources'>{resource}</div>                                   
              <div className="github-icon">
                <Link className='projectLink' to={projectLink}>
                  {showProject && <MdOpenInNew size='28px'></MdOpenInNew>}
                </Link>
                <a target="_blank" rel='noreferrer' href={githubLink}>{!inDev && <AiFillGithub size='28px'></AiFillGithub>}</a>  
              </div>
            </div>
        </div>

    )
}

export default Card