import React from 'react'
import ContactCard from './ContactCard'
import './ContactBackground.css'
const ContactBackground = () => {
    return (
        <div className='gradient'>
            <div className='container'>
                <ContactCard />

            </div>
        </div>
    )
}

export default ContactBackground
