import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FiGithub, FiLinkedin} from 'react-icons/fi';

function Footer() {
  return (
      <>
    <div className='footer-container'>
      <div className='footer-links'>
          <div className='footer-link-items'>
            <Link to= '/'>Home</Link>
          </div>
            <div className='footer-link-items'>
                <Link to= '/resume'>Resume</Link>
            </div>
            <div className='footer-link-items'>
                <Link to= '/contact'>Contact</Link>
            </div>
            <div className='footer-link-items'>
            <a target="_blank" rel='noreferrer' href="https://github.com/AndyLam1224"><FiGithub></FiGithub></a>             
            </div>
            <div className='footer-link-items'>
                <a target="_blank" rel='noreferrer' href="https://www.linkedin.com/in/andy-lam-9b595aa6/"><FiLinkedin></FiLinkedin></a>             

            </div>
        </div>
    </div>    
    </>
  );
}

export default Footer;