import React from 'react'
import {homeObjOne} from './Data'
import HeroSection from '../../HeroSection'
import ProjectSection from '../../ProjectSection'
import AboutSection from '../../AboutSection'

const Home = () => {
    return (
      <>
          <HeroSection {...homeObjOne} />  
          <AboutSection />
          <ProjectSection />  
      </>
    );
}

export default Home
