import React from 'react'
import './AboutSection.css'
const AboutSection = () => {
    return (
        <div className='about'>
            {<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#D8DEE9" fill-opacity="1" d="M0,96L60,106.7C120,
        117,240,139,360,133.3C480,128,600,96,720,85.3C840,75,960,85,1080,90.7C1200,
        96,1320,96,1380,96L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,
        320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </svg>}
            <div className='about-section'>
                <div className="about-body">
                        <h1>About Me</h1>
                        <p>I am currently working as a sofware engineer at Cutlass Systems Engineering doing Python and C++ development. My current focuses are in web development, algorithmic trading, and startups. If you have a business idea or just want to talk feel free to send me a message! </p>
                </div>          
                <div className="about-description">
                    <img src= '/images/bitmoji.png' alt='bitmoji'></img>
                </div>
                
            </div>
        </div>
    )
}

export default AboutSection
