import React from 'react'
import Card from './Card'
import './ProjectSection.css'
import {projObjOne, projObjTwo, projObjThree, projObjFour, projObjFive, projObjSix} from './ProjectData'
import Fade from 'react-reveal/Fade';

const ProjectSection = () => {
    return (
        <>
        <div className='home__project-section'>
            {<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#E5E9F0" fill-opacity="1" d="M0,96L120,117.3C240,139,480,181,720,176C960,171,1200,117,1320,90.7L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>}
        <h1 className='project-title'>My Projects</h1>

        <Fade bottom>
            <div className='cardHolders'>    
                    <Card {...projObjOne}/>
                    <Card {...projObjTwo}/>
                    <Card {...projObjThree}/>
                    <Card {...projObjFour}/>
                    <Card {...projObjFive}/>
                    <Card {...projObjSix}/>
            </div>
        </Fade>
        
           </div>
        </>
    )
}

export default ProjectSection
