import React from 'react'

const SkillIcons = ({svg}) => {
    return (
        <div>
            {svg}
        </div>
    )
}

export default SkillIcons
