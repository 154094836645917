import React from 'react'
import ContactBackground from './ContactBackground'

const Contact = () => {
    return (
        <div>
            <ContactBackground />  
        </div>
    )
}

export default Contact
