import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {FaBars, FaTimes }  from 'react-icons/fa'
import './Navbar.css'
import { IconContext } from 'react-icons/lib'


const Navbar = () => {
    const [click, setClick] = useState(false);
    const [, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false)
        }
        else {
            setButton(true)
        }
    };

    useEffect(() => {
        showButton();
      }, []);
    
      window.addEventListener('resize', showButton)
    return (
        <>
        <IconContext.Provider value = {{ color: '#fff'}}>
            <div className="navbar">
                <div className="navbar-container container">
                    <Link to='/' className="navbar-logo"  onClick={closeMobileMenu}>                        
                        <img className='navbar-icon' alt='logo' onClick ={closeMobileMenu} src='images/mylogo.png'></img>
                        Andy Lam
                    </Link> 
                    <div className="menu-icon" onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/resume' className='nav-links' onClick={closeMobileMenu}>
                                Resume
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                                Contact Me
                            </Link>
                        </li>                    
                    </ul>
                </div>
            </div>
        </IconContext.Provider>
        </>
    )
}

export default Navbar
