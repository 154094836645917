import React from 'react'
import './Resume.css'
import SkillIcons from './SkillIcons'
import { aws, cpp, css, git, html, java, javascript, python, react, unix } from './SkillIconData'
import Bounce from 'react-reveal/Bounce'
import Resumepdf from './Resumepdf.pdf'
const Resume = () => {
    return (
        <div className='resume-page'>
        <div className='resume-heading'>
            <h1>Resume</h1>
            <a href={Resumepdf}>View as a PDF</a>
        </div>
        <div className='skills-section'>
            <h3 className='resume-section-heading'>Skills</h3>
            <hr className='page-break'></hr>
            <div className='skills'>
                <Bounce left duration={2000}>

                <ul>
                    <li><SkillIcons {...cpp}/></li>
                    <li><SkillIcons {...python}/></li>
                    <li><SkillIcons {...java}/></li>
                    <li><SkillIcons {...unix}/></li>
                    <li><SkillIcons {...git}/></li>
                    
                </ul>
                </Bounce>
                <Bounce right duration={2000}>

                <ul>
                    <li><SkillIcons {...javascript}/></li>
                    <li><SkillIcons {...react}/></li>
                    <li><SkillIcons {...html}/></li>
                    <li><SkillIcons {...css}/></li>                    
                    <li><SkillIcons {...aws}/></li>
                </ul>
                </Bounce>
            </div>
        </div>
        <h3 className='resume-section-heading'>Experience</h3>    
            <hr className='page-break'></hr> 
        <div className='experience-section'>
            <div className='container left'>
                <div className='date-worked'>Dec 2020 - Present</div>
                <div className='date-worked'>Dec 2019 - Dec 2020</div>
                <div className='date-worked'>Sep 2017 - Dec 2017</div>

            </div>
            <div className='exp-container right'>

                <div className='work-section'>
                    <div className='body'>
                        <div className='description'>
                            <div className='exp-title'>
                                <h3>Software Engineer</h3>
                                <h4>Cutlass Systems Engineering</h4>
                                <p className='date-hidden'>Dec 2020 - Present</p>
                            </div>
                            <ul>
                                <li>Designed and developed company website as sole developer using HTML, CSS, and JavaScript</li>
                                <li>Develop software in C++ to advance a high performance computing framework controlling interaction of federated simulations.</li>
                                <li>Prototype software tools such as simulation controllers, data extraction/reduction, visualization, and system-of-systems analysis.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='work-section'>            
                    <div className='body'>
                        <div className='description'>
                            <div className='exp-title'>
                                <h3>Software Engineer Analyst</h3>
                                <h4>Accenture Federal Services</h4>
                                <p className='date-hidden'>Dec 2019 - Dec 2020</p>
                            </div>
                            <ul>
                                <li>Developed and maintained web application using Pega BPM and Java</li>
                                <li>Worked on back-end production support to resolve over 50 help tickets daily</li>
                                <li>Developed a Proof of Concept for a transcription application utilizing AWS and presented to a regional panel for developers</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='work-section'>
                    <div className='body'>
                        <div className='description'>
                            <div className='exp-title'>
                                <h3>Engineering Intern</h3>
                                <h4>Gryphon Technologies</h4>  
                                <p className='date-hidden'>Sep 2017 - Dec 2017</p>          
                            </div>
                            <ul>
                                <li>Navy goal of 50% decrease in fuel consumption through analysis of data sets for correlations</li>
                                <li>Developed 2D AutoCAD models to visualize ideal lighting test conditions</li>
                                <li>Researched HVAC components and system controls and performed statistical analysis to draw correlations between current draw and external conditions</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>     
        </div>
        <div className='education-section'>
            <h3 className='resume-section-heading'>Education</h3>
            <hr className='page-break'></hr>
            <div className='title'>
                <h3>B.S. in Mechanical Engineering</h3>
                <h4>Virginia Polytechnic Institute and State University</h4>
                <p>2019</p>
            </div>
        </div>
        </div>
    )
}

export default Resume
