import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/HomePage/Home'
import Footer from './components/pages/Footer/Footer'
import Resume from './components/pages/Resume/Resume'
import Contact from './components/pages/Contact/Contact'
import Snake from './components/pages/Snake/Snake'
import ScrollToTop from './components/ScrollToTop'
function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path ='/' exact component={Home} />
          <Route path ='/resume' component={Resume} />
          <Route path ='/contact' component={Contact} />
          <Route path ='/snake' component={Snake} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
